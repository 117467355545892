import { useLayoutEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  offset: number,

  getOffset(includeHeader?: boolean, includeSubheader?: boolean, includeLayoutMessage?: boolean): number,
}

const useGetMainSideBarOffset = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const { header, subHeader } = useEoValue(LAYOUT_STATE)
  const [ offset, setOffset ] = useState<number>(0)
  const { height: layoutMessageContainerHeight } = useGetDOMNodeProperties('layout_message__container')
  const { height: headerHeight } = useGetDOMNodeProperties('layout_header')
  const { height: subHeaderHeight } = useGetDOMNodeProperties('layout_sub_header')

  const getOffset = (includeHeader = true, includeSubheader = true, includeLayoutMessage = true): number => {
    let calculatedOffset = 0

    if (includeLayoutMessage) {
      calculatedOffset += layoutMessageContainerHeight
    }

    if (includeHeader) {
      calculatedOffset += headerHeight
    }

    if (includeSubheader) {
      calculatedOffset += subHeaderHeight
    }

    return calculatedOffset
  }

  useLayoutEffect(() => {
    setOffset(getOffset())
  }, [
    firm?.settings.isOffline,
    header,
    headerHeight,
    subHeader,
    subHeaderHeight,
    layoutMessageContainerHeight
  ])

  return {
    offset,
    getOffset
  }
}

export default useGetMainSideBarOffset
