import { Box } from '@mui/material'
import ShoppingCartContainer from '~/src/components/cart/shopping-cart-container'
import OrderingMethodsContainer from '~/src/components/ordering-methods/OrderingMethodsContainer'
import useGetMainSideBarTemplate from '~/src/components/layout/main-side-bar/styles/useGetMainSideBarTemplate'
import OrderSummary from '~/src/components/orders/OrderSummary'
import useGetMainSideBarMaxHeight from '~/src/hooks/layout/useGetMainSideBarMaxHeight'
import useGetMainSideBarOffset from '~/src/hooks/layout/useGetMainSideBarOffset'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE, LayoutState } from '~/src/stores/layout'

const MainSideBar = () => {
  const styles = useGetMainSideBarTemplate()
  const { mainSideBar } = useEoValue<LayoutState>(LAYOUT_STATE)
  const { getMaxHeight } = useGetMainSideBarMaxHeight()
  const { getOffset } = useGetMainSideBarOffset()

  return (
    <Box
      id='layout_main_sidebar'
      component='aside'
      gridArea='main-sidebar'
      sx={styles.getBoxSx(getMaxHeight, getOffset)}
    >
      {mainSideBar.displayDeliveryOptions && (
        <Box
          gridArea='ordering-methods'
          id='layout_main_sidebar__OrderingMethodsContainer'>
          <OrderingMethodsContainer />
        </Box>
      )}

      {mainSideBar.displayCart && (
        <Box
          gridArea='shopping-cart'
          id='layout_main_sidebar__ShoppingCartContainer'
          sx={{
            overflow: 'hidden'
          }}
        >
          <ShoppingCartContainer />
        </Box>
      )}

      {mainSideBar.displayOrderSummary && (
        <Box
          gridArea='order-summary'
          id='layout_main_sidebar__OrderSummary'
          sx={{
            overflow: 'hidden'
          }}
        >
          <OrderSummary />
        </Box>
      )}
    </Box>
  )
}

export default MainSideBar
