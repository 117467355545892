import { SxProps } from '@mui/material'
import StylesShoppingCartContainerServiceInterface
  from '~/src/components/cart/shopping-cart-container/styles/StylesShoppingCartContainerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesShoppingCartContainerServiceStyles extends TemplateServiceStyleBase implements StylesShoppingCartContainerServiceInterface {
  public getContainerSxProps(): SxProps {
    return {}
  }

  public getTitleSxProps(): SxProps {
    return {
      pb: 2,
      color: 'background.contrastText'
    }
  }

  public getCardsContainerSxProps(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      gap: 2
    }
  }

  public getShoppingCartCardSxProps(): SxProps {
    return {
      overflow: 'hidden',
      p: 0,
      maxHeight: 'calc(100% - 16px - 16px - 1.2rem)',
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr',
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText'
    }
  }

  public getPhysicalLoyaltyVoucherCardSxProps(): SxProps {
    return {
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText'
    }
  }
}
