import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesOrderingMethodsSummaryCardServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-card/styles/StylesOrderingMethodsSummaryCardServiceInterface'
import getOrderingMethodsSummaryCardTemplateService
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-card/styles/GetOrderingMethodsSummaryCardTemplateService'

const useGetOrderingMethodsSummaryCardTemplate = (): StylesOrderingMethodsSummaryCardServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('orderingMethodsSummary')

  return getOrderingMethodsSummaryCardTemplateService(componentStyle, muiTheme)
}

export default useGetOrderingMethodsSummaryCardTemplate
