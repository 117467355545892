import { Box, SxProps } from '@mui/material'
import OrderingMethodsSummaryCard
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-card'
import OrderingMethodsResponsiveDialog from '~/src/components/ordering-methods/OrderingMethodsResponsiveDialog'
import OrderingMethodsTitle from '~/src/components/ordering-methods/OrderingMethodsTitle'

interface Props {
  sx?: SxProps,
}

const OrderingMethodsContainer = ({ sx }: Props) => {
  return (
    <Box
      id='delivery_methods_container'
      sx={sx}
    >
      <OrderingMethodsTitle />
      <OrderingMethodsResponsiveDialog activator={<OrderingMethodsSummaryCard />} />
    </Box>
  )
}

export default OrderingMethodsContainer
