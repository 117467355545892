import { useCallback, useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  maxHeight: number,
  getMaxHeight(
    includeHeader?: boolean,
    includeSubheader?: boolean,
    includeFooter?: boolean,
    includeLayoutMessage?: boolean
  ): number,
}

const useGetMainSideBarMaxHeight = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const layout = useEoValue(LAYOUT_STATE)
  const [ maxHeight, setMaxHeight ] = useState<number>(0)
  const { height: layoutMessageContainerHeight } = useGetDOMNodeProperties('layout_message__container')
  const { height: headerHeight } = useGetDOMNodeProperties('layout_header')
  const { height: subHeaderHeight } = useGetDOMNodeProperties('layout_sub_header')
  const { height: footerHeight } = useGetDOMNodeProperties('layout_footer')

  const getMaxHeight = useCallback(
    (includeHeader = true, includeSubheader = true, includeFooter = true, includeLayoutMessage = true): number => {
      let calculatedMaxHeight = 0

      if (includeLayoutMessage) {
        calculatedMaxHeight += layoutMessageContainerHeight
      }

      if (includeHeader) {
        calculatedMaxHeight += headerHeight
      }

      if (includeSubheader) {
        calculatedMaxHeight += subHeaderHeight
      }

      if (includeFooter) {
        calculatedMaxHeight += footerHeight
      }

      return calculatedMaxHeight
    }, [ layout, firm?.settings.isOffline,layoutMessageContainerHeight, headerHeight, subHeaderHeight, footerHeight ])

  useEffect(() => {
    setMaxHeight(getMaxHeight())
  }, [
    firm?.settings.isOffline,
    layout.header,
    headerHeight,
    layout.subHeader,
    subHeaderHeight,
    layout.footer,
    footerHeight
  ])

  return {
    maxHeight,
    getMaxHeight
  }
}

export default useGetMainSideBarMaxHeight
