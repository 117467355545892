import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesOrderingMethodsSummaryCardServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-card/styles/StylesOrderingMethodsSummaryCardServiceInterface'
import { CardProps, SxProps } from '@mui/material'

export default class StylesOrderingMethodsSummaryCardServiceStyleOne extends TemplateServiceStyleBase implements StylesOrderingMethodsSummaryCardServiceInterface {
  getCardProps(): CardProps {
    return {
      sx: this.getCardSx()
    }
  }

  getCardSx(): SxProps {
    return { mt: 2 }
  }
}
