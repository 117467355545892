import ShoppingCart from '~/src/components/cart/shopping-cart'
import useGetShoppingCartContainerTemplate
  from '~/src/components/cart/shopping-cart-container/styles/useGetShoppingCartContainerTemplate'
import Card from '~/src/components/mui-wrappers/Card'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import ShoppingCartPhysicalLoyaltyVoucherCard
  from '~/src/components/cart/shopping-cart-loyalty-voucher/ShoppingCartPhysicalLoyaltyVoucherCard'
import { Box } from '@mui/material'

const OrderSummary = () => {
  const { t } = useTranslations()
  const styles = useGetShoppingCartContainerTemplate()

  return (
    <>
      <Title
        variant='h6'
        sx={styles.getTitleSxProps()}
      >
        {t('order.summary')}
      </Title>
      <Box sx={styles.getCardsContainerSxProps()}>
        <Card sx={styles.getShoppingCartCardSxProps()}>
          <ShoppingCart
            showActions={false}
            showQuantitySelector={false}
            showDeleteButton={false}
            showLoyaltySummary
            showQuantityAsText
          />
        </Card>

        <ShoppingCartPhysicalLoyaltyVoucherCard />
      </Box>
    </>
  )
}

export default OrderSummary
