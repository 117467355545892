import { paperClasses, SxProps } from '@mui/material'
import StylesMainSideBarServiceInterface
  from '~/src/components/layout/main-side-bar/styles/StylesMainSideBarServiceInterface'
import { MainSideBarState } from '~/src/stores/layout'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMainSideBarServiceStyleTwo extends TemplateServiceStyleBase implements StylesMainSideBarServiceInterface {
  public getBoxSx(getMaxHeight: CallableFunction, getOffset: CallableFunction): SxProps {
    const hasHeader: boolean = this.layout?.header.display ?? false
    const hasFooter: boolean = this.layout?.footer.display ?? false
    const mainSideBarState: MainSideBarState = this.layout!.mainSideBar

    return {
      position: 'sticky',
      maxHeight: `calc(100vh - ${getMaxHeight(hasHeader, false, hasFooter)}px)`,
      top: `${getOffset(hasHeader, false)}px`,
      pr: 12,
      pb: 6,
      pt: 3,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateAreas: '' +
        `${mainSideBarState.displayDeliveryOptions ? '"ordering-methods"' : ''}` +
        `${mainSideBarState.displayCart ? '"shopping-cart"' : ''}` +
        `${mainSideBarState.displayOrderSummary ? '"order-summary"' : ''}`,
      gridTemplateRows: '' +
        `${mainSideBarState.displayDeliveryOptions ? ' max-content' : ''}` +
        `${mainSideBarState.displayCart ? ' 1fr' : ''}` +
        `${mainSideBarState.displayOrderSummary ? ' 1fr' : ''}`,
      gap: 2,
      [`div.${paperClasses.root}`]: {
        boxShadow: this.muiTheme.elevation
      },
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  }
}
