import { Card } from '@mui/material'
import useGetShoppingCartContainerTemplate
  from '~/src/components/cart/shopping-cart-container/styles/useGetShoppingCartContainerTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import CheckoutPhysicalLoyaltyVoucherSection
  from '~/src/components/cart/shopping-cart-loyalty-voucher/ShoppingCartPhysicalLoyaltyVoucherSection'
import { IS_LOGGED_IN_AS_SELECTOR_FAMILY } from '~/src/stores/authentication'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'

const CheckoutPhysicalLoyaltyVoucherCard = () => {
  const loyaltyIsEnabled = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const styles = useGetShoppingCartContainerTemplate()
  const loggedInAsCustomer = useEoValue(IS_LOGGED_IN_AS_SELECTOR_FAMILY('customer'))

  if (!loggedInAsCustomer || loyaltyIsEnabled) {
    return null
  }

  return (
    <Card sx={{
      ...styles.getPhysicalLoyaltyVoucherCardSxProps(),
      p: 2,
      pt: 1.5
    }}>
      <CheckoutPhysicalLoyaltyVoucherSection />
    </Card>
  )
}

export default CheckoutPhysicalLoyaltyVoucherCard
