import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderingMethodsSummaryCardServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-card/styles/StylesOrderingMethodsSummaryCardServiceInterface'
import StylesOrderingMethodsSummaryCardServiceStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-card/styles/StylesOrderingMethodsSummaryCardServiceStyleTwo'
import StylesOrderingMethodsSummaryCardServiceStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-card/styles/StylesOrderingMethodsSummaryCardServiceStyleOne'

const getOrderingMethodsSummaryCardTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesOrderingMethodsSummaryCardServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderingMethodsSummaryCardServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderingMethodsSummaryCardServiceStyleOne(muiTheme)
  }
}

export default getOrderingMethodsSummaryCardTemplateService
