import Card from '~/src/components/mui-wrappers/Card'
import OrderingMethodsSummaryContainer
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-container'
import useGetOrderingMethodsSummaryCardTemplate
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-card/styles/useGetOrderingMethodsSummaryCardTemplate'

const OrderingMethodsSummaryCard = () => {
  const styles = useGetOrderingMethodsSummaryCardTemplate()

  return (
    <Card {...styles.getCardProps()}>
      <OrderingMethodsSummaryContainer/>
    </Card>
  )
}

export default OrderingMethodsSummaryCard
